import React from "react";

import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import MailIcon from "@material-ui/icons/Mail";

import logoWhite from "../../assets/coolspring-logowhite-23.svg";

import "./style.css";

const Footer = () => {
	let currentYear = new Date().getFullYear();
	return (
		<div className="footer">
			<div className="footerWrapper">
				<div className="logo-title">
					<img src={logoWhite} />
				</div>
				<div className="footerGrid">
					{/* <div className="column center">
						<p className="about">
                        Coolspring Trading and Marketing Limited holds itself out and operates as a world-class commodities, trading and services company, with particularly deep interactions, collaborations, synergies and experiences along a diverse spectrum in the in the Petroleum Industry.
                        </p>
						<p className="about">
							While established in 2010, Coolspring Trading and Marketing’s roots and essence had been in existence through the work of its directors,
							 who possess a well-built combination of experiences, totalling over forty years in key areas of the petroleum value chain.  
						</p>
					</div> */}
					<div className="column">
						<h3 className="title">Contact Us</h3>
						<span className="footerLinks first not-links">
							<HomeIcon className="icon" />
							<span>
								Plot 1706A Olugbosi Close, off Bishop Oluwole, Victoria Island,
								Lagos
							</span>
						</span>
						<span className="footerLinks not-links">
							<PhoneInTalkIcon className="icon" />
							<span>+234-1-227 3352-6</span>
						</span>
						<span className="footerLinks not-links">
							<MailIcon className="icon" />
							<span>info@coolspringtrading.com</span>
						</span>
					</div>
					<div className="column">
						<h3 className="title">Company</h3>
						<span className="footerLinks first">
							<Link to="/about/our-history">Our History</Link>
						</span>
						<span className="footerLinks">
							<Link to="/about/mission-and-vision">Mission and Vision</Link>
						</span>
						<span className="footerLinks">
							<Link to="/about/our-culture">Our Culture</Link>
						</span>
						<span className="footerLinks">
							<Link to="/our-people/working-at-coolspring">
								Working at Coolspring
							</Link>
						</span>
						<span className="footerLinks">
							<Link to="/our-people/careers">Careers</Link>
						</span>
						<span className="footerLinks">
							<Link to="/contact">Contact</Link>
						</span>
					</div>

					<div className="column">
						<h3 className="title">What we do</h3>
						<span className="footerLinks">
							<Link to="/what-we-do/commodity-trading">Commodity Trading</Link>
						</span>
						<span className="footerLinks">
							<Link to="/what-we-do/shipping-and-logistics">
								Shipping and Logistics
							</Link>
						</span>
						<span className="footerLinks">
							<Link to="/what-we-do/marketing">Marketing</Link>
						</span>
					</div>
					<div className="column">
						<h3 className="title">Our Values</h3>
						<span className="footerLinks">
							<Link to="/our-values/corporate-goverance">
								Corporate Goverance
							</Link>
						</span>
						<span className="footerLinks">
							<Link to="/our-values/community-affairs">Community Affairs</Link>
						</span>
						<span className="footerLinks">
							<Link to="/our-values/hse">HSE</Link>
						</span>
						<span className="footerLinks">
							<Link to="/our-values/code-of-conduct">Code of Conduct</Link>
						</span>
					</div>
				</div>

				<div className="footerLegalBlock">
					<div className="text">
						Copyright © Coolspring Trading And Marketing {currentYear} - All
						rights reserved.
					</div>
					<div className="socialLinks"></div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
