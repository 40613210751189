import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import aboutBckGround from "../../../assets/COMMODITYTRADING2.jpg";
import tradingPic2 from "../../../assets/COMMODITYTRADING5.jpg";

import AOS from "aos";
import "aos/dist/aos.css";
import "./style.css";
const Trading = () => {
	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);
	return (
		<div className="what-we-do-container">
			<Helmet>
				<title>Commodity Trading</title>
			</Helmet>
			<div
				className="what-we-do-header trading"
				style={{ backgroundImage: `url(${aboutBckGround})` }}>
				<div className="my-text-bg">
					<h1>Commodity Trading</h1>
				</div>
			</div>

			<div className="home-story my-swapped my-colored-Bg">
				<div className="my-column">
					<div className="my-contents">
						<div className="text-wrapper" style={{ textAlign: "justify" }}>
							<h3
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								Commodity Trading
							</h3>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="33 ">
								Coolspring has built up world-class capabilities, gathering and
								applying deep experiences in the area of commodities trading of
								Crude Oil, NGLs, Condensates, and Petroleum Products.
							</p>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="50">
								Through a network of international partners and trading desks
								located at major trading centers, Coolspring has been able to
								trade hundreds of millions in product volume, on a competitive
								basis, while ensuring effective risk management coverage.
							</p>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="100">
								Our trading team maintains comprehensive coverage of physical
								requirements and availability of various commodities and
								petroleum products, by utilizing its market intelligence
								network, market analysis systems, and continual dialogue with
								primary market participants.
							</p>
						</div>
					</div>
				</div>
				<div className="my-column">
					<div
						className="img-wrapper"
						style={{ backgroundImage: `url(${tradingPic2})` }}
						data-aos="fade-left"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200"></div>
				</div>
			</div>
		</div>
	);
};

export default Trading;
