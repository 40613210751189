import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import aboutBckGround from "../../../assets/ourhistorypic.jpg";

import "./style.css";

import AOS from "aos";
import "aos/dist/aos.css";

const OurHistory = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);

	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);

	return (
		<div className="about-page-container">
			<Helmet>
				<title>Our History</title>
			</Helmet>
			<div
				className="about-page-header"
				style={{ backgroundImage: `url(${aboutBckGround})` }}>
				<div className="my-text-bg">
					<h1>Our History</h1>
				</div>
			</div>
			<div className="our-history-wrapper">
				{/* <div className="main-title-div">
            <h2>Company's Milestones</h2>
            <p><b>Coolspring Trading and Marketing Limited</b> holds itself out and operates as world-class commodity trading and marketing company, with particularly deep interactions, collaborations, synergies and experiences along diverse spectrum in the Petroleum Industry
            </p>
            </div> */}
				<div className="home-story no-padding">
					<div className="my-column">
						<div className="my-contents">
							<div className="text-wrapper" style={{ textAlign: "justify" }}>
								<h3
									data-aos="fade-right"
									data-aos-easing="ease-in-out"
									data-aos-duration="500">
									The Coolspring Story
								</h3>
								<p
									data-aos="fade-right"
									data-aos-easing="ease-in-out"
									data-aos-duration="500"
									data-aos-delay="200">
									Coolspring Trading & Marketing Limited holds itself out and
									operates as a world-class commodity trading and marketing
									company, with particularly deep interactions, collaborations,
									synergies, and experiences along a diverse spectrum in the
									Petroleum Industry.
								</p>
								<p
									data-aos="fade-right"
									data-aos-easing="ease-in-out"
									data-aos-duration="500"
									data-aos-delay="200">
									While established in 2010, Coolspring Trading & Marketing’s
									roots and essence had been in existence through the world of
									its management who has worked for international and indigenous
									trading companies, thus ensuring a professional approach to
									business and an in-depth knowledge of hydrocarbon commodities
									flow and markets. The company's interest can be broadly
									categorized into the following: Supply/Purchase of Crude oil,
									NGLs, Condensate,and refined Petroleum Products on a term/spot
									contract basis.
								</p>
								<p
									data-aos="fade-right"
									data-aos-easing="ease-in-out"
									data-aos-duration="500"
									data-aos-delay="200">
									Coolspring's ability to provide a top-quality range of
									services is evident in the experience of its management led by
									the Managing Director, Mr. Bowale Jolaoso, an accountant by
									training skilled in the areas of hydrocarbon supply and
									trading, marketing, and terminal operations.
								</p>
								<p
									data-aos="fade-right"
									data-aos-easing="ease-in-out"
									data-aos-duration="500"
									data-aos-delay="200">
									Coolspring's long-term goal is to be a fully integrated energy
									company that transports its cargoes using its vessels to
									service its distribution system and satisfy its customers.
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="milestone-wrapper">
            <h3 className="date-title">May <span>2020</span></h3>
            <div className="milestone-flex">
                <div className="my-text-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
                    <h4>Title of milestone</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>
                <div className="my-img-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="200">
                    <img src={historyPic}/>
                </div>
            </div>
            </div>
            <div className="milestone-wrapper">
            <h3 className="date-title">June <span>2021</span></h3>
            <div className="milestone-flex my-left">
            
                <div className="my-text-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="200">
                    <h4>Title of milestone</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>
                <div className="my-img-wrapper my-left" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
                    <img src={historyPic}/>
                </div>
               
            </div>
            </div>
            <div className="milestone-wrapper">
            <h3 className="date-title">August <span>2021</span></h3>
            <div className="milestone-flex">
                <div className="my-text-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="700">
                    <h4>Title of milestone</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>
                <div className="my-img-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="700" data-aos-delay="200">
                    <img src={historyPic}/>
                </div>
            </div>
            </div> */}
			</div>
		</div>
	);
};

export default OurHistory;
