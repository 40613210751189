import React, { useEffect } from "react";
import storyPicture from "../../../assets/CULTURE2.jpg";

import aboutBckGround from "../../../assets/culture.jpg";

import { Helmet } from "react-helmet";

import AOS from "aos";
import "aos/dist/aos.css";
import "./style.css";

const Culture = ({ history, match }) => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);
	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);
	useEffect(() => {
		if (history.location.hash) {
			document
				.getElementById("our-team")
				.scrollIntoView({ behavior: "smooth" });
		} else {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		}
	}, []);
	return (
		<div className="about-page-container">
			<Helmet>
				<title>Our Culture</title>
			</Helmet>
			<div
				className="about-page-header"
				style={{ backgroundImage: `url(${aboutBckGround})` }}>
				<div className="my-text-bg">
					<h1>Our Culture</h1>
				</div>
			</div>

			<div className="home-story">
				<div className="my-column">
					<div className="my-contents">
						<div className="text-wrapper" style={{ textAlign: "justify" }}>
							<h3
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								Coolspring’s Culture Code
							</h3>
							<p
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="200">
								A corporate culture practiced by the entire workforce
								contributes to employee job satisfaction and profitability. At
								Coolspring, our culture is defined by our values, traditions,
								beliefs, and attitude. We make policies that constitute a
								pervasive context of everything we do and plan.
							</p>
							<p
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="200">
								We commit strongly to both our mission and core values, and we
								believe it will help us earn the regard of many and the
								resources to further our mission. We are enthusiastic about
								both.
							</p>
							<p
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="200">
								We look to the long term, we share openly, and are remarkably
								transparent. We believe our best strategy is our people, as
								success is making those who believe in you look brilliant. We
								want to be as proud of the people we grow as we are of the
								company we grow. Our best people do not just fit our culture,
								they further it.
							</p>
							<p
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="200">
								We are open to change and strive towards continuous improvement
								and optimization in all our business divisions. We work
								continuously in improving our product, our services, and
								ourselves. We continuously reflect on ourselves and our
								services, honor good performance and express our appreciation
								clearly.
							</p>
						</div>
					</div>
				</div>
				<div className="my-column">
					<div
						className="img-wrapper"
						style={{ backgroundImage: `url(${storyPicture})` }}
						data-aos="fade-left"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200"></div>
				</div>
			</div>
			{/* <div className="our-team-wrapper" id="our-team">
                    <div className="main-text-wrapper">
                        <h3>Our Team</h3>
                    </div>
                    <div className="my-team-flex">
                        <div className="my-img-wrapper">
                            <img src={team1}/>
                        </div>
                        <div className="my-text-wrapper">
                            <h4>Omotoun Dosumu, <span>Managing Director / CEO</span></h4>
                            <p>Drawing from a track record of high performance, transformation and originality, Omotoun provides
    inspirational and revolutionary leadership for Coolspring Trading and Marketing. With deep experiences along the value
    chain of Oil Product Origination, Trading, Terminalling, Marketing and Supply and firsthand knowledge in shipping, storage and transportation, operations of clean products (Gasoline, Gas Oil and Aviation Fuel), LPG and Crude Oil, she has been able to take leading positions and create value in prior industry work engagement with Taleveras Group and other companies.</p>
                        </div>
                    </div>
                    <div className="my-team-flex my-right">
                        <div className="my-img-wrapper">
                            <img src={team1}/>
                        </div>
                        <div className="my-text-wrapper">
                            <h4>Omotoun Dosumu, <span>Managing Director / CEO</span></h4>
                            <p>Drawing from a track record of high performance, transformation and originality, Omotoun provides
    inspirational and revolutionary leadership for Coolspring Trading and Marketing. With deep experiences along the value
    chain of Oil Product Origination, Trading, Terminalling, Marketing and Supply and firsthand knowledge in shipping, storage and transportation, operations of clean products (Gasoline, Gas Oil and Aviation Fuel), LPG and Crude Oil, she has been able to take leading positions and create value in prior industry work engagement with Taleveras Group and other companies.</p>
                        </div>
                    </div>
            </div> */}
		</div>
	);
};

export default Culture;
