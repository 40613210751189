import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import aboutBckGround from "../../../assets/safetybg.jpg";
import codePic from "../../../assets/code-of-conduct.jpeg";
import hsePic from "../../../assets/Safetypic.jpg";
import hsePolicyPic from "../../../assets/safety2.png";
import hseMainpic from "../../../assets/hsemain.png";

import "./style.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./style.css";

const HSEPolicy = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);
	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);
	return (
		<div className="our-values-container">
			<Helmet>
				<title> HSE Policy</title>
			</Helmet>
			<div
				className="about-page-header"
				style={{ backgroundImage: `url(${hsePolicyPic})` }}>
				<div className="my-text-bg">
					<h1>HSE Policy</h1>
				</div>
			</div>

			<div className="home-story">
				<div className="my-column">
					<div className="my-contents">
						<div className="text-wrapper" style={{ textAlign: "justify" }}>
							<h3
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								HSE Policy Statement
							</h3>
							<p
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="33">
								We, at Coolspring pledge to conduct our business in a safe
								manner putting into consideration the safety of People and the
								Environment as our top priority while achieving the best
								interest of the organization, employees, valued customer and
								associates.
							</p>
							<p
								className="my-p-list"
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="50">
								We are committed to;{" "}
							</p>
							<ul
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="100"
								style={{ textAlign: "justify" }}>
								<li>
									Fulfilling our commitment towards people, society, and
									environment by proactive HSE Management System.
								</li>
								<li>
									Instilling cultural awareness where all employees are
									committed to HSE by promoting awareness across every aspect of
									the organization.
								</li>
								<li>
									Identify potential risks in activities being performed and
									mitigate the same to prevent injuries and ill-health to the
									personnel involved in these activities in the workplace.
								</li>
								<li>
									Ensure compliance with the requirement of health, safety, and
									environment during the execution of product/services as per
									applicable codes, standards, procedures, and legal/statutory
									requirements.
								</li>
								<li>
									Establish a system of continuous HSE inspection/monitoring of
									assets to eliminate potential risk/unsafe conditions.
								</li>
								<li>
									Strive for continual improvement in the HSE management system
									by periodically reviewing performance and making necessary
									changes.
								</li>
								<li>Periodically review HSE Policy for value addition.</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="my-column">
					<div
						className="img-wrapper"
						style={{ backgroundImage: `url(${hseMainpic})` }}
						data-aos="fade-left"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200"></div>
				</div>
			</div>
		</div>
	);
};

export default HSEPolicy;
