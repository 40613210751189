import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import aboutBckGround from "../../../assets/missionmainpic.jpg";
import missionPic from "../../../assets/mission.jpg";
import visionpic from "../../../assets/visionpic.jpg";
import core from "../../../assets/core-values.png";

import "./style.css";
import AOS from "aos";
import "aos/dist/aos.css";

const MissionVision = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);

	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);
	return (
		<div className="about-page-container">
			<Helmet>
				<title>Mission and Vision</title>
			</Helmet>
			<div
				className="about-page-header"
				style={{ backgroundImage: `url(${aboutBckGround})` }}>
				<div className="my-text-bg">
					<h1>Mission and Vision</h1>
				</div>
			</div>
			<div className="our-mission-container">
				<div className="home-story no-padding">
					<div className="my-column">
						<div className="my-contents">
							<div className="text-wrapper">
								<h3>Coolspring Attributes</h3>
								<p>
									Coolspring Trading & Marketing Limited’s attributes are mainly
									contained in its vision, mission and core values.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="mission-div-box-shadow">
					<div className="my-mission-wrapper">
						<div className="my-img-wrapper">
							<img src={visionpic} />
						</div>

						<div className="my-text-wrapper">
							<h3>Vision</h3>
							<p style={{ textAlign: "justify" }}>
								To be the leading energy and commodity company, involved in
								creating and driving value within its sphere of influence and
								operations.
							</p>
						</div>
					</div>
					<div className="my-mission-wrapper vision ">
						<div className="my-img-wrapper">
							<img src={missionPic} />
						</div>
						{/* <div className="my-text-wrapper my-colored-Bg"> */}
						<div className="my-text-wrapper">
							<h3>Mission</h3>
							<p style={{ textAlign: "justify" }}>
								Committed to seeking opportunities and platforms for growth and
								value-creation through self-investments, industry partnerships,
								and deployment of world-class services to all clients
								irrespective of size or scale.
							</p>
						</div>
					</div>
					<div className="my-mission-wrapper">
						<div className="my-img-wrapper">
							<img src={core} />
						</div>

						<div className="my-text-wrapper">
							<h3>Core Values</h3>
							<ul style={{ textAlign: "justify" }}>
								<li>
									<span>Knowledge: </span> In possession of properly defined and
									developed information and data sets that ensure overawing
									performance.
								</li>
								<li>
									<span>Resilience: </span>Inherently tough, possessing enduring
									strength to last the distance and achieve set goals.
								</li>
								<li>
									<span>Professionalism: </span> Instilled with industry
									experiences and competencies which allows for excellent and
									world-class service delivery.
								</li>
								<li>
									<span>Collaboration: </span>Built-up to work with partners and
									stakeholders to achieve set objectives and targets
								</li>
								<li>
									<span>Innovation: </span>Focused on deploying new ways,
									methods, and improvements in pursuit of corporate success and
									accomplishments.
								</li>
								<li>
									<span>Integrity: </span>Our operations and activities are
									bound, guided, and guarded by ethical and moral principles
									which guarantee the unwavering assurance of honesty and
									adherence to industry rules/standards.
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MissionVision;
// Knowledge: </span>In possession of properly defined and developed information and data sets which ensures overawing performance
// Resilience: </span>Inherently tough, possessing enduring strength to last the distance and achieve set goals Professionalism: </span>Imbued with industry experiences and competencies which allows for excellent and
// world-class service delivery
// Collaboration: </span>Built up to work with partners and stakeholders to achieve set objectives and targets
// Innovation: </span>Focused on deploying new ways, methods and improvements in pursuit of corporate success and accomplishments
// Integrity: </span>Our operations and activities are bound, guided and guarded by ethical and moral principles which guarantees unwavering assurance of honesty and adherence to industry rules / standards
