import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useForm, ValidationError } from "@formspree/react";

import HomeIcon from "@material-ui/icons/Home";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import MailIcon from "@material-ui/icons/Mail";

import aboutBckGround from "../../../assets/contactPic.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import "./style.css";

const Contact = () => {
	const [state, handleSubmit] = useForm("xqkwykao");
	const [submit, setSubmit] = useState("");
	const [mail, setEmail] = useState("");
	const [name, setName] = useState("");
	const [textArea, setTextArea] = useState("");

	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);

	useEffect(() => {
		setSubmit(state);
		if (state.succeeded) {
			//         for(const form of document.getElementsByTagName('form')) {
			// form.reset();
			setTextArea("");
			setEmail("");
			setName("");
			console.log(submit);
		}
	}, [state.succeeded]);

	console.log(state.succeeded);

	return (
		<>
			<div
				className="about-page-header"
				style={{ backgroundImage: `url(${aboutBckGround})` }}>
				<Helmet>
					<title>Contact Us</title>
				</Helmet>
				<div
					className="my-text-bg"
					style={{ backgroundColor: "#00000029" }}></div>
			</div>
			<div className="home-story">
				<div className="my-column contact-form">
					<h3>Get in Touch</h3>
					{state.succeeded && (
						<div className="contact-form-success">
							<p>Thank you, your message has been Sent!</p>
						</div>
					)}
					{state.errors.length ? (
						<div className="contact-form-error">
							{state.errors.map((error, index) => (
								<p key={index}>{error.message}</p>
							))}
						</div>
					) : null}
					<form name="contact" onSubmit={handleSubmit}>
						<label htmlFor="name">Name</label>
						<input
							type="text"
							name="name"
							autoFocus
							placeholder="Enter your Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							required
						/>
						<label htmlFor="email">Email Address</label>
						<input
							type="email"
							name="email"
							placeholder="Email"
							value={mail}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
						<ValidationError
							prefix="Email"
							field="email"
							errors={state.errors}
						/>
						<label htmlFor="message">Enter your Message</label>
						<textarea
							name="message"
							rows="4"
							placeholder="Enter Message"
							value={textArea}
							onChange={(e) => setTextArea(e.target.value)}
							required></textarea>
						<ValidationError
							prefix="Message"
							field="message"
							errors={state.errors}
						/>
						<button
							type="submit"
							disabled={state.submitting}
							onClick={() => {}}>
							{state.submitting ? "Sending..." : "Send"}
						</button>
					</form>
				</div>
				<div className="my-column contact-details-wrapper">
					<h3>Contact Details</h3>
					<span className="footerLinks not-links">
						<HomeIcon className="icon" />
						<span>
							Plot 1706A Olugbosi Close, off Bishop Oluwole, Victoria Island,
							Lagos
						</span>
					</span>
					<span className="footerLinks not-links">
						<PhoneInTalkIcon className="icon" />
						<span>+234-1-227 3352-6</span>
					</span>
					<span className="footerLinks not-links">
						<MailIcon className="icon" />
						<span>info@coolspringtrading.com</span>
					</span>
				</div>
			</div>
		</>
	);
};

export default Contact;
