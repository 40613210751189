import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import aboutBckGround from "../../../assets/shippicture1.jpg";
import storyPicture from "../../../assets/MARKETING.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import "./style.css";

const Marketing = () => {
	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);
	return (
		<div className="what-we-do-container">
			<Helmet>
				<title>Marketing</title>
			</Helmet>
			<div
				className="what-we-do-header"
				style={{ backgroundImage: `url(${aboutBckGround})` }}>
				<div className="my-text-bg">
					<h1>Marketing</h1>
				</div>
			</div>

			<div className="home-story">
				<div className="my-column">
					<div className="my-contents">
						<div className="text-wrapper" style={{ textAlign: "justify" }}>
							<h3
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								Marketing
							</h3>
							<p
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="33 ">
								Coolspring possesses adept capacities in securing favorable
								deals either during supply gluts or scarcity periods,
								irrespective of the level of inherent complexities and linkages.
							</p>
							<p
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="50">
								These attributes enable us to compete effectively in the world
								energy market. We standardize, coordinate, and integrate our
								trading activities across countries in an attempt to build an
								efficient operations network and take maximum advantage of
								similarities across locations. We are also responsive to
								specific needs within a variety of host countries.
							</p>
						</div>
					</div>
				</div>
				<div className="my-column">
					<div
						className="img-wrapper"
						style={{ backgroundImage: `url(${storyPicture})` }}
						data-aos="fade-left"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200"></div>
				</div>
			</div>
		</div>
	);
};

export default Marketing;
