import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import aboutBckGround from "../../../assets/shipping.jpg";
import storyPicture from "../../../assets/shipping.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

import "./style.css";
const Shipping = () => {
	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);
	return (
		<div className="what-we-do-container">
			<Helmet>
				<title>Shipping and Logistics</title>
			</Helmet>
			<div
				className="what-we-do-header"
				style={{ backgroundImage: `url(${aboutBckGround})` }}>
				<div className="my-text-bg">
					<h1>Shipping and Logistics</h1>
				</div>
			</div>

			<div className="home-story">
				<div className="my-column">
					<div className="my-contents">
						<div className="text-wrapper" style={{ textAlign: "justify" }}>
							<h3
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								Shipping and Logistics
							</h3>
							<p
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="33 ">
								Coolspring arranges and manages shipping and logistics services
								for commodities and petroleum products, either in furtherance of
								its trading activities or for other third parties.{" "}
							</p>
							<p
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="50">
								With decades’ worth of experience in shipping and chartering
								alongside valuable industry relationships.
							</p>
							<p
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="100">
								Coolspring possesses adept capacities in securing favorable
								deals either during supply gluts or scarcity periods,
								irrespective of the level of inherent complexities and linkages.
							</p>
						</div>
					</div>
				</div>
				<div className="my-column">
					<div
						className="img-wrapper"
						style={{ backgroundImage: `url(${storyPicture})` }}
						data-aos="fade-left"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200"></div>
				</div>
			</div>
			{/* <div className="home-story my-swapped my-colored-Bg">
<div className="my-column">
<div className="my-contents">
<div className="text-wrapper">
<h3 data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500">Logistics</h3>
        <p data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="33 ">Coolspring arranges and manages shipping and logistics services for commodities and petroleum products, either in furtherance of its trading activities or for other third parties. </p>
        <p data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="50">
        With decades worth of experiences in shipping and chartering alongside valuable industry relationships.</p>
        <p data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="100">
        Coolspring possesses adept capacities in securing favourable deals either during supply gluts or scarcity periods, irrespective of the level of inherent complexities and linkages..</p>

    </div>
</div>
    
</div>
<div className="my-column">
    <div className="img-wrapper" style={{backgroundImage:`url(${storyPicture})`}} data-aos="fade-left" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="200">
    </div>
</div>
</div> */}
		</div>
	);
};

export default Shipping;
