import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import aboutBckGround from "../../../assets/corporate.jpg";
import coproatePic from "../../../assets/corporate.png";
import codePic from "../../../assets/code-of-conduct.jpeg";

import "./style.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Corporate = () => {
	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);
	return (
		<div className="our-values-container">
			<Helmet>
				<title>Corporate Governance</title>
			</Helmet>
			<div
				className="about-page-header"
				style={{ backgroundImage: `url(${aboutBckGround})` }}>
				<div className="my-text-bg">
					<h1>Corporate Governance</h1>
				</div>
			</div>
			<div className="home-story">
				<div className="my-column">
					<div className="my-contents">
						<div className="text-wrapper" style={{ textAlign: "justify" }}>
							<h3
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								Corporate Governance
							</h3>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								Coolspring promotes fairness, openness, and transparency in its
								responsibilities to all stakeholders (shareholders, managers,
								employees, suppliers, customers among others). We conduct our
								business operation in a conscious, deliberate, and sustained
								manner to strike a judicious balance between our interests and
								those of our various stakeholders and the environment.
							</p>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								Our business model facilitates economic efficiency by focusing
								on value-enhancing activities and aids efficient allocation of
								scarce resources.
							</p>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								At Coolspring, our managers are empowered to have some level of
								discretion in making decisions within the context of existing
								laws, regulations, and expectations of stakeholders. We operate
								on the premise that there is a link between good governance and
								the law.
							</p>
						</div>
					</div>
				</div>
				<div className="my-column">
					<div
						className="img-wrapper"
						style={{ backgroundImage: `url(${coproatePic})` }}
						data-aos="fade-right"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200"></div>
				</div>
			</div>
			<div className="home-story my-swapped my-colored-Bg">
				<div className="my-column">
					<div className="my-contents">
						<div className="text-wrapper" style={{ textAlign: "justify" }}>
							<h3
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								Code of Conduct
							</h3>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								Our Code of Conduct aims to communicate the principles and
								standards of business ethics and conduct which we expect from
								our people and all those who work with us.
							</p>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								This Code not only applies to employees and officers of
								Coolspring Trading & Marketing Limited but also to all other
								parties who work on the Company’s behalf, including contractors,
								suppliers, and partners. All employees of Coolspring have a
								general duty of care towards Coolspring’s personnel and assets
								and are responsible for gaining an understanding of and
								complying with the Code. The Code should be read carefully and
								understood fully.
							</p>
						</div>
						<div
							className="link-wrapper"
							data-aos="fade-in"
							data-aos-easing="ease-in-out"
							data-aos-duration="500">
							<Link to="/our-values/code-of-conduct">Read more</Link>
						</div>
					</div>
				</div>
				<div className="my-column">
					<div
						className="img-wrapper"
						style={{ backgroundImage: `url(${codePic})` }}
						data-aos="fade-right"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200"></div>
				</div>
			</div>
		</div>
	);
};

export default Corporate;
