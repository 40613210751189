import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import aboutBckGround from "../../../assets/community-picture.jpg";
import codePic from "../../../assets/code-of-conduct.jpeg";
import communityPic from "../../../assets/community.PNG";

import "./style.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./style.css";

const Community = () => {
	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);
	return (
		<div className="our-values-container">
			<Helmet>
				<title>Community Affairs</title>
			</Helmet>
			<div
				className="about-page-header"
				style={{ backgroundImage: `url(${aboutBckGround})` }}>
				<div className="my-text-bg">
					<h1>Community Affairs</h1>
				</div>
			</div>

			<div className="home-story">
				<div className="my-column">
					<div className="my-contents">
						<div className="text-wrapper" style={{ textAlign: "justify" }}>
							<h3
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								Our Community
							</h3>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="33 ">
								Our operations can affect the social and economic environment of
								the communities where we operate. Our influence on our people
								and communities is directly proportional to our ability to
								operate sustainably. Giving back to the community is in our
								culture and code.
							</p>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="50">
								We engage with local communities, government, business partners,
								and other stakeholders to understand the issues and concerns of
								the communities in which we work and we seek to make a positive
								contribution. Coolspring’s Community Affairs Policy is a vital
								part of management’s responsibility for good community
								relationships.
							</p>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="100">
								Our community affairs policy for implementation of operations
								has put in place all the necessary procedures and activities
								aimed at achieving satisfactory management of community affairs
								issues. Coolspring develops its diverse relationship with
								external constituencies in the area it operates to create
								replicable and unique opportunities for producing life-enhancing
								initiatives for the community and its members to help achieve
								community development goals in partnership with community
								organizations, institutions, businesses, and government.
							</p>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="100">
								We work hard to fulfill our obligation as a responsive
								organizational partner to our surrounding neighborhoods.
								Coolspring is not only in the community, but also an integral
								part of the community. We contribute to the community and social
								development through carrying out our business activities, paying
								taxes as well as providing employment, skills development,
								trading with local enterprises, and making targeted social
								investments following NCDMD Policy. We seek to encourage the
								development of local skills and promote the use by our suppliers
								and contractors of cost-effective local goods and services,
								provided standards can be met and maintained.
							</p>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="100">
								Our Corporate Social Responsibility (CSR) Policy is reviewed
								annually and its performance is measured enabling the future
								identification of areas that may require improvement.
							</p>
						</div>
					</div>
				</div>
				<div className="my-column">
					<div
						className="img-wrapper"
						style={{ backgroundImage: `url(${communityPic})` }}
						data-aos="fade-right"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200"></div>
				</div>
			</div>
		</div>
	);
};

export default Community;
