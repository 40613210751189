import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import aboutBckGround from "../../../assets/safetybg.jpg";
import codePic from "../../../assets/code-of-conduct.jpeg";
import hsePic from "../../../assets/Safetypic.jpg";
import hsePolicyPic from "../../../assets/safetyNew.jpeg";
// import hsePolicyPic from "../../../assets/safety2.png"
import hseMainpic from "../../../assets/hsemain.png";

import "./style.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./style.css";

const HSE = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);
	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);
	return (
		<div className="our-values-container">
			<Helmet>
				<title> HSE</title>
			</Helmet>
			<div
				className="about-page-header hse"
				style={{ backgroundImage: `url(${hsePolicyPic})` }}>
				<div className="my-text-bg">
					<h1>HSE</h1>
				</div>
			</div>

			<div className="home-story  my-swapped my-colored-Bg">
				<div className="my-column">
					<div className="my-contents">
						<div className="text-wrapper" style={{ textAlign: "justify" }}>
							<h3
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								Health, Safety and Environment
							</h3>
							<p
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="33">
								Our dedication to making sure a healthy and safe work
								environment is provided for our employees, partners, vendors,
								and contractors is always the first thing we consider when we
								carry out business activities, procedures, and decisions.
							</p>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="50">
								Coolspring has dedicated time and resources to creating an
								efficient HSE Management System. The system concentrates on
								critical activities and ensures that they are properly
								controlled and that measurements are made and reported to enable
								monitoring of overall performance and identification of areas
								for improvement. Management systems shall provide a structured
								process for the achievement of continual improvement, the rate
								of which is generally set by the organization itself taking into
								consideration employees and contractors.
							</p>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="100">
								At Coolspring the safety and security of our people and the
								environment is our primary concern, and that is why Coolspring
								ensures activities are carried out with high safety standards in
								place. Coolspring’s HSE Policy requires our company to follow a
								systematic approach to HSE management and it is designed to
								ensure compliance with the law and to achieve continuous
								performance improvement. In shouldering the responsibilities of
								ensuring compliance Coolspring has devoted HSE assets across the
								organization to instill HSE culture.
							</p>
						</div>
					</div>
				</div>
				<div className="my-column">
					<div
						className="img-wrapper"
						style={{ backgroundImage: `url(${hsePic})` }}
						data-aos="fade-left"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200"></div>
				</div>
			</div>
			<div className="home-story">
				<div className="my-column">
					<div className="my-contents">
						<div className="text-wrapper" style={{ textAlign: "justify" }}>
							<h3
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								HSE Policy Statement
							</h3>
							<p
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="33">
								We, at Coolspring pledge, to conduct our business in a safe
								manner putting into consideration the safety of People and the
								Environment as our top priority while achieving the best
								interest of the organization, employees, valued customers, and
								associates.
							</p>
						</div>
						<div
							className="link-wrapper"
							data-aos="fade-in"
							data-aos-easing="ease-in-out"
							data-aos-duration="500">
							<Link to="/our-values/hse-policy">Read more</Link>
						</div>
					</div>
				</div>
				<div className="my-column">
					<div
						className="img-wrapper"
						style={{ backgroundImage: `url(${hseMainpic})` }}
						data-aos="fade-left"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200"></div>
				</div>
			</div>
		</div>
	);
};

export default HSE;
