import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import homeBackgroundImage from "../../../assets/picture1.jpg";
import pic2 from "../../../assets/greenenergy.jpg";
import pic3 from "../../../assets/safetypic1.jpg";

import shipPicture1 from "../../../assets/shippicture1.jpg";

import storyPicture from "../../../assets/ourhistorypic.jpg";
import servicePicture2 from "../../../assets/service-07.svg";
import servicePicture3 from "../../../assets/service-03.svg";
import servicePicture4 from "../../../assets/service-04.svg";

import AOS from "aos";
import "aos/dist/aos.css";
import "./style.css";

const HomePage = () => {
	const [showSecondImage, setShowSecondImage] = useState(false);
	const [showThirdImage, setShowThirdImage] = useState(false);
	const [showFourthImage, setShowFourthImage] = useState(false);
	const [showFirst, setShowFirst] = useState(true);

	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);

	const backgroundImgStyles = {
		// animation: `${animate} 16s infinite`,
		backgroundImage: `url(${homeBackgroundImage})`,
	};
	useEffect(() => {
		setTimeout(() => {
			setShowSecondImage(true);
			setShowFirst(false);
		}, 4000);

		setTimeout(() => {
			setShowSecondImage(false);
			setShowThirdImage(true);
		}, 10000);
		setTimeout(() => {
			setShowThirdImage(false);
			setShowFourthImage(true);
		}, 14000);
		setTimeout(() => {
			setShowFourthImage(false);
		}, 19000);
		setInterval(() => {
			setTimeout(() => {
				setShowSecondImage(true);
			}, 4000);

			setTimeout(() => {
				setShowSecondImage(false);
				setShowThirdImage(true);
			}, 10000);
			setTimeout(() => {
				setShowThirdImage(false);
				setShowFourthImage(true);
			}, 14000);
			setTimeout(() => {
				setShowFourthImage(false);
			}, 19000);
		}, 19000);
	}, []);

	return (
		<>
			<div className="my-home-container">
				<Helmet>
					<title>Coolspring Trading and Marketing Limited</title>
				</Helmet>
				<div
					className="background-img-wrapper"
					id="slide1"
					style={backgroundImgStyles}>
					<div
						className={`backgroundImageWrapper2 ${
							showSecondImage ? "show" : "hide"
						}`}
						id="slide2"
						style={{ backgroundImage: `url(${pic2})` }}></div>
					<div
						className={`backgroundImageWrapper3 ${
							showThirdImage ? "show" : "hide"
						}`}
						id="slide3"
						style={{ backgroundImage: `url(${pic3})` }}></div>
					<div
						className={`backgroundImageWrapper4 ${
							showFourthImage ? "show" : "hide"
						}`}
						id="slide4"
						style={{
							backgroundImage: `url(${shipPicture1})`,
							backgroundPosition: "right",
						}}></div>
					<div className="backgroundTextBackground">
						<div className="home-main-background-img-contents">
							{/* <h4 className="title" data-aos="fade-in" data-aos-easing="ease-in-out" data-aos-duration="650">Welcome</h4> */}
							{/* <h4 className="title" data-aos="fade-in" data-aos-easing="ease-in-out" data-aos-duration="650">Welcome to Coolspring</h4> */}
							<div className="main-text-div">
								<h1
									className={`main-text ${
										showFirst
											? ""
											: showSecondImage
											? "hide"
											: showThirdImage
											? "hide"
											: showFourthImage
											? "hide"
											: showFirst
											? ""
											: "show"
									} `}
									data-aos="fade-in"
									data-aos-easing="ease-in-out"
									data-aos-duration="650"
									data-aos-delay="200">
									Discover what makes us stand out!
								</h1>
								<h1
									className={`main-text long ${
										showSecondImage ? "show" : "hide"
									}`}>
									Redefining the energy world to proffer innovative solutions.
								</h1>
								<h1 className={`main-text ${showThirdImage ? "show" : "hide"}`}>
									Safety our Pride, Safety our Commitment.
								</h1>
								<h1
									className={`main-text ${showFourthImage ? "show" : "hide"}`}>
									{" "}
									We are homegrown but we trade energy globally.
								</h1>
							</div>
							<div className="tagline-div">
								{/* <h4 className={ `tagline slide ${showFourthImage ? "show" : "hide" } `}>
                    Let us be your partner of choice</h4>
                    <h4 className={ `tagline slide ${showThirdImage ? "show" : "hide" } `}>
                    We are Homegrown but we trade energy globally
                    </h4>
                    <h4 className={ `tagline slide ${showSecondImage ? "show" : "hide" } `}>
                    Let us be your partner of choice </h4>  */}
								<h4
									className={`tagline slide`}
									data-aos="fade-in"
									data-aos-easing="ease-in-out"
									data-aos-duration="650"
									data-aos-delay="400">
									Let us be your partner of choice{" "}
								</h4>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="home-story">
				<div className="my-column">
					<div className="my-contents">
						<div className="text-wrapper">
							<h3
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								The Coolspring Story
							</h3>
							<p
								className="first"
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								style={{ textAlign: "justify" }}>
								Coolspring Trading & Marketing Limited holds itself out and
								operates as a world-class commodity trading and marketing
								company, with particularly deep interactions, collaborations,
								synergies, and experiences along a diverse spectrum in the
								Petroleum Industry.
							</p>
						</div>
						<div
							className="link-wrapper"
							data-aos="fade-in"
							data-aos-easing="ease-in-out"
							data-aos-duration="500"
							data-aos-delay="200">
							<Link to="/about/our-history">Read more</Link>
						</div>
					</div>
				</div>
				<div className="my-column">
					<div
						className="img-wrapper"
						style={{ backgroundImage: `url(${storyPicture})` }}
						data-aos="fade-left"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200">
						<div
							className="mission"
							data-aos="fade-up"
							data-aos-easing="ease-in-out"
							data-aos-duration="500"
							data-aos-delay="300">
							<VisibilityOutlinedIcon className="my-icon" />
							<h5> Our Vision</h5>
							<p>
								To be the leading energy and commodity company, involved in
								creating and driving value within its sphere of influence and
								operations.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="our-services-home">
				<h3
					className="main-title"
					data-aos="fade-right"
					data-aos-easing="ease-in-out"
					data-aos-duration="500">
					What We do
				</h3>
				<p
					className="main-desc"
					data-aos="fade-right"
					data-aos-easing="ease-in-out"
					data-aos-duration="500"
					data-aos-delay="200">
					Through the years, Coolspring has built up domain knowledge and
					competencies in three key areas, which mirrors its Service Offerings
					in a likewise manner.
				</p>
				<div className="services-grid-container">
					<div
						className="my-card"
						data-aos="fade-right"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200">
						<div className="my-icon-wrapper">
							<img src={servicePicture3} alt="services" />
						</div>
						<h4 className="title">Commodity Trading</h4>
						<p className="desc">
							Coolspring’s has built up world-class capabilities, gathering and
							applying deep experiences in the area of commodities trading of
							Crude Oil, NGls, Naphtha, Condensates and petroleum products.
						</p>
						<div className="link-wrapper">
							<Link to="/what-we-do/commodity-trading">Read more</Link>
						</div>
					</div>
					<div
						className="my-card"
						data-aos="fade-right"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="300">
						<div className="my-icon-wrapper">
							<img src={servicePicture2} alt="services" />
						</div>
						<h4 className="title">Shipping and Logistics</h4>
						<p className="desc">
							Coolspring arranges and manages shipping and logistics services
							for commodities and petroleum products, either in furtherance of
							its trading activities or for other third parties.
						</p>
						<div className="link-wrapper">
							<Link to="/what-we-do/shipping-and-logistics">Read more</Link>
						</div>
					</div>
					<div
						className="my-card"
						data-aos="fade-right"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="400">
						<div className="my-icon-wrapper">
							<img src={servicePicture4} alt="services" />
						</div>
						<h4 className="title">Marketing</h4>
						<p className="desc">
							Coolspring is a homegrown commodity trading and marketing company,
							our marketing activities involves physical sourcing of crude oil,
							NGLs, condensates and petroleum products, and delivering them to
							our customers globally.
						</p>
						<div className="link-wrapper">
							<Link to="/what-we-do/marketing">Read more</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HomePage;
