import React from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import HomePage from './components/pages/HomePage';
import Header from './components/Header';
import Footer from './components/Footer';


import "aos"
import OurHistory from './components/pages/About/OurHistory';
import MissionVision from './components/pages/About/MissionVision';
import Culture from './components/pages/About/Culture';
import Trading from './components/pages/WhatWeDo/Trading';
import Shipping from './components/pages/WhatWeDo/Shipping';
import Marketing from './components/pages/WhatWeDo/Marketing';
import Corporate from './components/pages/Our Values/Corporate';
import HSE from './components/pages/Our Values/HSE';
import Community from './components/pages/Our Values/Community';
import Careers from './components/pages/Careers';
import Contact from './components/pages/Contact';
import CodeOfConduct from './components/pages/Our Values/Code';
import WorkingAt from './components/pages/Careers/WorkingAt';
import HSEPolicy from './components/pages/Our Values/HSEPolicy';




const App = () => {
  return (
    <>
      <Router>
      <Header/>
        <Switch>
          <Route exact path="/" component={HomePage}/>
          <Route exact path="/about/our-history" component={OurHistory}/>
          <Route exact path="/about/mission-and-vision" component={MissionVision}/>
          <Route exact path="/about/our-culture" component={Culture}/>
          <Route exact path="/what-we-do/commodity-trading" component={Trading}/>
          <Route exact path="/what-we-do/shipping-and-logistics" component={Shipping}/>
          <Route exact path="/what-we-do/marketing" component={Marketing}/>
          <Route exact path="/our-values/corporate-goverance" component={Corporate}/>
          <Route exact path="/our-values/hse" component={HSE}/>
          <Route exact path="/our-values/hse-policy" component={HSEPolicy}/>
          <Route exact path="/our-values/community-affairs" component={Community}/>
          <Route exact path="/our-values/code-of-conduct" component={CodeOfConduct}/>
          <Route exact path="/our-people/working-at-coolspring" component={WorkingAt}/>
          <Route exact path="/our-people/careers" component={Careers}/>
          <Route exact path="/contact" component={Contact}/>
        </Switch>
        <Footer/>
      </Router>
    </>
  )
}

export default App
