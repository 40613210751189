import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import aboutBckGround from "../../../assets/Careers.png";
import storyPicture from "../../../assets/Careers2.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "./style.css";

const Careers = () => {
	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<>
			<div
				className="what-we-do-header"
				style={{ backgroundImage: `url(${aboutBckGround})` }}>
				<Helmet>
					<title>Careers</title>
				</Helmet>
				<div className="my-text-bg">
					<h1>Careers</h1>
				</div>
			</div>
			{/* <div className="home-story my-swapped my-colored-Bg">
                <div className="my-column">
                <div className="my-contents">
                <div className="text-wrapper">
                <h3 data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500">Working at Coolspring</h3>
                        <p data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="33">Our operations and strategic initiatives are presently being driven a team of young professionals who are deeply experienced and thoroughly committed to the mission, vision and core values of Coolspring Trading and Limited.</p>
                        <p className="my-p-list" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="50">
                        Notwithstanding the lean nature of Coolspring’s operations, the key persons and management staff are have been to deliver outstanding results, far beyond their size</p>
                    </div>
                    <div className="link-wrapper" data-aos="fade-in" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="200">
                <Link to={{pathname: "/about/our-culture", hash:"#our-team"}} >See Our Team</Link>
                </div>
                </div>
                    
                </div>
                <div className="my-column">
                    <div className="img-wrapper" style={{backgroundImage:`url(${storyPicture})`}} data-aos="fade-left" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="200">
                    </div>
                </div>
            </div> */}
			<div className="home-story">
				<div className="my-column">
					<div className="my-contents">
						<div className="text-wrapper">
							<h3
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								Join Us Today
							</h3>
							<p
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="33"
								style={{ textAlign: "justify" }}>
								Our operations and strategic initiatives are presently being
								driven by a team of young professionals who are deeply
								experienced and thoroughly committed to the mission, vision and
								core values of Coolspring Trading and Marketing Limited.
							</p>
							<p
								className="my-p-list"
								data-aos="fade-right"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="50">
								Join us today by sending your cv to{" "}
								<a href="mailto:careers@coolspringtrading.com" className="link">
									careers@coolspringtrading.com
								</a>
							</p>
						</div>
						<div
							className="link-wrapper"
							data-aos="fade-in"
							data-aos-easing="ease-in-out"
							data-aos-duration="500"
							data-aos-delay="200">
							{/* <Link to="/">Join Us Today</Link> */}
						</div>
					</div>
				</div>
				<div className="my-column">
					<div
						className="img-wrapper"
						style={{ backgroundImage: `url(${storyPicture})` }}
						data-aos="fade-left"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200"></div>
				</div>
			</div>
		</>
	);
};

export default Careers;
