import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import codePic from "../../../assets/code-of-conduct.jpeg";

import managementPic from "../../../assets/management.jpeg";

import "./style.css";
import AOS from "aos";
import "aos/dist/aos.css";

const CodeOfConduct = () => {
	AOS.init();
	useEffect(() => {
		AOS.init({ once: "true" });
		AOS.refresh();
	}, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);
	return (
		<div className="our-values-container">
			<Helmet>
				<title>Code of Conduct</title>
			</Helmet>
			<div
				className="about-page-header"
				style={{ backgroundImage: `url(${managementPic})` }}>
				<div className="my-text-bg">
					<h1>Code of Conduct</h1>
				</div>
			</div>
			<div className="home-story my-colored-Bg">
				<div className="my-column">
					<div className="my-contents">
						<div className="text-wrapper" style={{ textAlign: "justify" }}>
							<h3
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500">
								Our Code is Us
							</h3>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="33 ">
								Our Code of Conduct aims to communicate the principles and
								standards of business ethics and conduct which we expect from
								our people and all those who work with us.
							</p>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="50">
								This Code not only applies to employees and officers of
								Coolspring Trading & Marketing Limited but also to all other
								parties who work on the Company’s behalf, including contractors,
								suppliers, and partners. All employees of Coolspring have a
								general duty of care towards Coolspring’s personnel and assets
								and are responsible for gaining an understanding of and
								complying with the Code. The Code should be read carefully and
								understood fully.{" "}
							</p>
							<p
								data-aos="fade-left"
								data-aos-easing="ease-in-out"
								data-aos-duration="500"
								data-aos-delay="100">
								Our people and managers have an important role to play in
								ensuring the requirements of the Code are applied in all work
								activities. Managers should drive best practices by
								demonstrating their commitment to the Code through good
								leadership and personal behaviors.
							</p>
						</div>
					</div>
				</div>
				<div className="my-column">
					<div
						className="img-wrapper"
						style={{ backgroundImage: `url(${codePic})` }}
						data-aos="fade-right"
						data-aos-easing="ease-in-out"
						data-aos-duration="500"
						data-aos-delay="200"></div>
				</div>
			</div>
			<div className="home-story my-colored-Bg conduct-wrapper">
				<div
					className="code-conduct"
					data-aos="fade-up"
					data-aos-easing="ease-in-out"
					data-aos-duration="400">
					<h4>Relationships with Suppliers and Partners</h4>
					<p>
						Building strong relationships with our suppliers and partners is
						vital to our success. We work with others who seek to comply with
						legal requirements and act in a manner that is consistent with
						Coolspring’s Code. We also exercise good judgment to act in a manner
						that will reflect favorably upon Coolspring and its personnel.
					</p>
					<p>
						Our Code, policies, and standards are communicated to suppliers and
						partners, all terms of agreements with business partners are
						approved by a member of our Legal Department, and also all terms of
						agreements with service providers are assessed and approved by a
						member of the Contracts Team.
					</p>
				</div>
				<div
					className="code-conduct"
					data-aos="fade-up"
					data-aos-easing="ease-in-out"
					data-aos-duration="400">
					<h4>Conflicts of Interest </h4>
					<p>
						We make business decisions based on what is in Coolspring’s best
						interests and not based on personal considerations or relationships.
						Actions or relationships conflict with or appear to conflict with,
						the interests of Coospring are to be avoided. All apparent or
						potential conflicts of interest are reported appropriately through
						line management.
					</p>
				</div>
				<div
					className="code-conduct"
					data-aos="fade-up"
					data-aos-easing="ease-in-out"
					data-aos-duration="400">
					<h4>Bribery and Corruption</h4>
					<p>
						We do not tolerate, permit, or engage in bribery, corruption, or
						improper payments of any kind in our business dealings anywhere in
						the world either with governments or the private sector. We have a
						fundamental commitment to comply with all applicable laws governing
						the conduct of our operations worldwide.
					</p>
					<p>
						This commitment includes laws against bribery and corruption. At
						Coolspring it is considered to be an offense to offer, promise or
						give a bribe, request or agree to receive or accept a bribe, to
						bribe a foreign or local public official to obtain or retain
						business, and for a commercial organization to fail to prevent
						bribery by those acting on its behalf.
					</p>
				</div>

				<div
					className="code-conduct"
					data-aos="fade-up"
					data-aos-easing="ease-in-out"
					data-aos-duration="400">
					<h4>Discrimination, Harassment or Bullying </h4>
					<p>
						We do not tolerate any form of harassment, bullying, or
						discrimination. At Coolspring, personnel must never use
						inappropriate jokes or derogatory comments as these are never
						acceptable. We pride ourselves in making sure that we encourage our
						people to speak out against discrimination, harassment, or bullying
						and support others who challenge or report it.
					</p>
				</div>
				<div
					className="code-conduct"
					data-aos="fade-up"
					data-aos-easing="ease-in-out"
					data-aos-duration="400">
					<h4>Human Rights </h4>
					<p>
						We are committed to respecting locally and internationally
						recognized human rights and we seek to ensure non-complicity in
						human rights abuses. We support the principles contained within the
						Universal Declaration of Human Rights through an approach guided by
						and aligned with the United Nations Guiding Principles on Business
						and Human Rights. Coolspring has zero-tolerance for slavery and
						human trafficking in all its different forms in any part of its
						business. Our Human Rights Policy is integrated into our CSR Policy.
						All personnel have a responsibility to respect human rights in their
						dealings with others.
					</p>
					<p>
						We do not employ forced, bonded or child labor, or any form of
						modern slavery and take all reasonable steps to ensure that this
						does not exist in our operations or our supply chain.
					</p>
				</div>
				<div
					className="code-conduct"
					data-aos="fade-up"
					data-aos-easing="ease-in-out"
					data-aos-duration="400">
					<h4>Managing Risks</h4>
					<p>
						We manage risk and seek to continually improve. Our approach to risk
						is set out in our Risk Management Policy and supported by our risk
						management standards, procedures, and risk appetite statement. We
						annually set several Key Performance Indicators (KPIs) which are
						designed to measure the delivery of the organizational strategy.
						These are cascaded to every team member allowing individual
						objectives to be agreed upon that support the delivery of the
						strategy.{" "}
					</p>
				</div>
			</div>
		</div>
	);
};

export default CodeOfConduct;
