import React, {useEffect} from 'react'
import {Link} from "react-router-dom"
import { Helmet } from 'react-helmet'

import aboutBckGround from "../../../assets/working-at-cool.jpg"
import storyPicture from "../../../assets/equality.jpg"
import AOS from 'aos';
import "aos/dist/aos.css";
import "./style.css"

const WorkingAt = () => {

    AOS.init()
    useEffect(() => {
        AOS.init({once:"true"});
        AOS.refresh();
      }, []);
      useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);

    return (
        <>
        <div className="what-we-do-header" style={{backgroundImage:`url(${aboutBckGround})`}}>
        <Helmet>
		    <title>Careers</title>
		</Helmet>
        <div className="my-text-bg">
            <h1>Working at Coolspring</h1>
        </div>
        </div>
            <div className="home-story my-swapped my-colored-Bg">
                <div className="my-column">
                <div className="my-contents">
                <div className="text-wrapper" style={{textAlign: 'justify'}}>
                <h3 data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500">Equality, Diversity and Fair Work </h3>
                        <p data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="33">
                        We seek to give and earn respect, empower our people and challenge conventional thinking. We require a positive working environment which respects an individual’s personal dignity and rights, and which is free from unfavorable treatment on the grounds of gender, disability, ethnic origin, marital status, age, responsibility for dependents, religion or beliefs or any other inappropriate distinction. 
                        </p>
                </div>
                    
                </div>
                    
                </div>
                <div className="my-column">
                    <div className="img-wrapper" style={{backgroundImage:`url(${storyPicture})`}} data-aos="fade-left" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="200">
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkingAt;
