import React,{useEffect, useState} from 'react'
import { Navbar, Nav, NavDropdown, NavDropdownButton} from "react-bootstrap";
import { Link } from 'react-router-dom';
import "./style.css"
import 'bootstrap/dist/css/bootstrap.min.css';


import logo from "../../assets/coolspring-logo-23.svg"
import logoWhite from "../../assets/coolspring-logowhite-23.svg"

const Header = () => {

    const [navScrolled, setNavScrolled] = useState(false)

    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', ()=> {
            if (window.scrollY > 20) {
                setNavScrolled(true);
            }
            else{
                setNavScrolled(false);
            }
        })
        // return () => {
          
        // }
    }, [])

   
    return (
            <Navbar expand="lg" expanded={expanded} fixed="top" className={navScrolled ? "my-header scrolled" : "my-header" }>
            <Navbar.Brand href="/" className="font-weight-bold"><img src={navScrolled ? logo: logoWhite}/></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" id="navbarBtn" onClick={() => {
                setNavScrolled(true);
                setExpanded(expanded ? false : "expanded")
            }} className={setNavScrolled? "hamburgerscrolled": null }/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                <Nav.Link onClick={() => setExpanded(false)}  as={Link} to="/" className="my-nav-links" onClick={() => setExpanded(false)} >Home</Nav.Link>
                <NavDropdown title="About" id="basic-nav-dropdown"  className="my-nav-links" renderMenuOnMount={true}>
                    <NavDropdown.Item onClick={() => setExpanded(false)}  as={Link} to="/about/our-history" aria-controls="basic-navbar-nav">Our History</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setExpanded(false)}  as={Link} to="/about/mission-and-vision">Mission and Vision</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setExpanded(false)}  as={Link} to="/about/our-culture">Our Culture</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="What We Do" id="basic-nav-dropdown"  className="my-nav-links" renderMenuOnMount={true}>
                    <NavDropdown.Item onClick={() => setExpanded(false)}  as={Link} to="/what-we-do/commodity-trading">Commodity Trading</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setExpanded(false)}  as={Link} to="/what-we-do/shipping-and-logistics">Shipping and Logistics</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setExpanded(false)}  as={Link} to="/what-we-do/marketing">Marketing</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Our Values" id="basic-nav-dropdown"  className="my-nav-links" renderMenuOnMount={true}>
                    <NavDropdown.Item onClick={() => setExpanded(false)}  as={Link} to="/our-values/corporate-goverance">Corporate Goverance</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setExpanded(false)}  as={Link} to="/our-values/community-affairs">Community Affairs</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setExpanded(false)}  as={Link} to="/our-values/hse">HSE</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Our People" id="basic-nav-dropdown"  className="my-nav-links" renderMenuOnMount={true}>
                    <NavDropdown.Item onClick={() => setExpanded(false)}  as={Link} to="/our-people/working-at-coolspring">Working at Coolspring</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setExpanded(false)}  as={Link} to="/our-people/careers">Careers</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link onClick={() => setExpanded(false)}  as={Link} to="/contact" className="my-nav-links">Contact Us</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Navbar>

    )
}

export default Header

{/* <Route exact path="/what-we-do/trading-crude-ngl-naphtha" component={Trading}/>
          <Route exact path="/what-we-do/-HSE Policy" component={Shipping}/>
          <Route exact path="/what-we-do/marketing" component={Marketing}/>
          <Route exact path="/our-values/corporate-goverance" component={Corporate}/>
          <Route exact path="/our-values/hse-policy" component={HSE}/>
          <Route exact path="/our-values/community-affairs" component={Community}/>
          <Route exact path="/careers" component={Careers}/>
          <Route exact path="/contact" component={Contact}/> */}